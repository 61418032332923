<template>
  <div class="page-classroom-view">
    <div class="my-page" style="padding-bottom: 45px;">
      <!-- 上方详情 -->
      <div class="my-card detail-box">
        <div class="card-top">
          <div class="card-title ell">{{ viewData.Title }}</div>
        </div>
        <div class="card-center">
          <span class="type1">{{ viewData.ChildrenName }}</span>
          <span class="type2">创建人：{{ viewData.CreateUserName }}</span>
        </div>
        <div class="card-bottom">
          <div class="content">{{ viewData.Content }}</div>
          <div class="img-d">
            <van-image
              v-for="(img, index) in viewData.accessoryList"
              :key="index"
              width="100"
              height="74"
              :src="img.url"
              @click.stop="
                () => {
                  images = []
                  images.push(img.url)
                  imgShow = true
                }
              "
              style="margin-right: 10px;margin-bottom:10px;border-radius: 6px;overflow: hidden;display: inline-block;"
            />
          </div>
          <div class="comment-d">
            <div class="left">
              <div class="good">
                <i class="i-good"></i>
                <span class="num">点赞{{ viewData.ThumbUpCount }}</span>
              </div>
            </div>
            <div class="right">
              <span class="date">{{ viewData.CreateDate }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论区 -->
      <div class="my-card comment-box">
        <div class="comment-title">所有评论（{{ viewData.CommentCount }}）</div>
        <div class="comment-list">
          <template
            v-if="viewData.commentList && viewData.commentList.length > 0"
          >
            <div
              v-for="(obj, index) in viewData.commentList"
              :key="index"
              class="comment-item"
            >
              <div class="user-box">
                <div class="user">
                  <img :src="defaultImg" />
                  <span>{{ obj.CommentUserName }}</span>
                </div>
                <div class="date">{{ obj.CreateDateStr }}</div>
              </div>
              <div class="word">{{ obj.Content }}</div>
            </div>
          </template>
          <van-empty v-else description="暂无评论" />
        </div>
      </div>
      <!-- 提交评论（家长） -->
      <div v-if="userType === '3' || userType === '2'" class="submit-box">
        <div class="left">
          <i></i>
          <input
            type="text"
            maxlength="20"
            placeholder="发表你的评论"
            v-model="comment"
            @keyup.enter="pushComment"
          />
        </div>
        <div class="right" @click="goodFn">
          <i></i>
          <span>赞{{ viewData.ThumbUpCount }}</span>
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import defaultImg from '@/assets/icon/i-comment-defalut.png'
import {
  Image,
  ImagePreview,
  Empty,
  Icon,
  Checkbox,
  CheckboxGroup,
  Dialog
} from 'vant'
export default {
  components: {
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Empty.name]: Empty,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Dialog.name]: Dialog
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      defaultImg: defaultImg,
      id: null,
      viewData: {},
      dataList: [{}, {}, {}, {}],
      comment: '',
      imgShow: false,
      images: [],
      result: []
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail () {
      let that = this
      this.$axios
        .get('/api/ChildrenCircle/Get', {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.viewData = res.data
            this.$nextTick(() => {
              setTimeout(() => {
                // 动态改变滚动高度
                const dom = that.$jq('.comment-list')
                dom.css(
                  'height',
                  that.$jq(window).height() -
                    dom.offset().top -
                    30 -
                    45 -
                    15 -
                    15
                )
              }, 200)
            })
          } else {
            that.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 点赞
    goodFn () {
      this.$axios
        .post('/api/ChildrenCircle/ThumbUp', {
          ID: this.viewData.ChildrenCircleID
        })
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg)
            this.getDetail()
          } else {
            this.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 评论
    pushComment () {
      this.$axios
        .post('/api/ChildrenCircle/Comment', {
          ChildrenCircleID: this.viewData.ChildrenCircleID,
          Content: this.comment
        })
        .then(res => {
          if (res.code === 200) {
            this.comment = ''
            this.$toast.success('评论成功')
            // this.$router.go(0)
            window.location.reload()
          } else {
            this.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 未读列表
    unread () {
      if (this.userType === '2') {
        this.$router.push({
          path: 'ClassRoomUnread',
          query: {
            id: this.id,
            unread: this.viewData.UnReadCount,
            read: this.viewData.ReadCount
          }
        })
      }
    },
    // 加入成长册
    join () {
      let that = this
      if (that.result.length > 0) {
        let params = {
          GrowthManual_ClassCircle: {
            ClassCircleID: that.id
          },
          AccessIDList: that.result
        }
        Dialog.confirm({
          title: '提示',
          message: '是否确定加入成长册？',
        })
          .then(() => {
            // on confirm
            params.IsOpen = true
            that.$axios
              .post('/api/ChildrenGrowthManual/AddByClassCircle', params)
              .then(res => {
                if (res.code === 200) {
                  that.$toast.success(res.msg || '操作成功')
                  setTimeout(() => {
                    that.$router.replace({
                      path: 'FamilyHome',
                      query: {
                        active: 0
                      }
                    })
                  }, 1000)
                } else {
                  that.$toast.fail(res.msg || '操作失败')
                }
              })
          })
          .catch(() => {
            // on cancel
            params.IsOpen = false
            that.$axios
              .post('/api/ChildrenGrowthManual/AddByClassCircle', params)
              .then(res => {
                if (res.code === 200) {
                  that.$toast.success(res.msg || '操作成功')
                  setTimeout(() => {
                    that.$router.replace({
                      path: 'FamilyHome',
                      query: {
                        active: 0
                      }
                    })
                  }, 1000)
                } else {
                  that.$toast.fail(res.msg || '操作失败')
                }
              })
          })
      } else {
        this.$toast.fail('请至少选择一张加入成长册的照片！')
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
